import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/EditProfile/EditProfile')
    )
  } else {
    return (
      import('@components/EditProfile/EditProfileMobile')
    )
  }
}

const EditProfile = ReactDynamicImport({ loader })

const EditProfilePage = () => <EditProfile />

export default EditProfilePage;
